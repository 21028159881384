<template>
    <div class="app-container">
        <div class="app-top-btn">
            <el-select
                v-model="listQuery.status"
                filterable
                clearable
                size="medium"
                @change="selectStatus"
            >
                <el-option
                    v-for="item in statusList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-date-picker
                style="float: left; margin-right: 10px"
                type="daterange"
                size="medium"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy年MM月dd日"
                value-format="yyyyMMdd"
                v-model="searchDatePicker"
                @change="searchDate"
            >
            </el-date-picker>
            <el-button
                type="danger"
                size="medium"
                @click="excelOut"
                style="float: right"
            >
                导出Excel
            </el-button>
        </div>

        <el-table :data="list" border fit highlight-current-row>
            <el-table-column
                align="center"
                label="身份证"
                min-width="180"
                prop="cardNo"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="用户名"
                min-width="120"
                prop="userName"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="工会名"
                min-width="160"
                prop="departmentName"
            >
            </el-table-column>
            <el-table-column
                align="center"
                label="手机号"
                min-width="160"
                prop="phone"
            >
            </el-table-column>
            <el-table-column align="center" label="申请时间" width="160">
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime }}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="申请未通过信息"
                min-width="160"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.message }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="状态" min-width="100">
                <template slot-scope="scope">
                    {{ getStatusStr(scope.row.status) }}
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="操作"
                fixed="right"
                width="180"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.status === 1"
                        type="primary"
                        size="mini"
                        @click="changeStatus(scope.row, 'status', 0)"
                    >
                        审核
                    </el-button>
                    <el-button v-else type="default" size="mini">
                        已审核
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页-->
        <div class="app-pagination">
            <el-pagination
                background
                layout="total, prev, pager, next"
                :total="listTotal"
                :page-size="listQuery.pageSize"
                :current-page="listQuery.pageNum"
                @current-change="pageNumChange"
            >
            </el-pagination>
        </div>

        <!--修改状态-->
        <el-dialog
            :title="changeStatusDialog.title"
            :visible.sync="changeStatusDialog.show"
            :modal-append-to-body="false"
            :close-on-click-modal="false"
            :width="changeStatusDialog.width"
        >
            <el-form
                :model="changeStatusDialog.fields"
                :ref="changeStatusDialog.ref"
            >
                <el-form-item prop="status" label="审核：">
                    <el-radio-group v-model="changeStatusDialog.fields.status">
                        <el-radio
                            v-for="item in [
                                {
                                    id: 2,
                                    title: '申请通过',
                                },
                                {
                                    id: 3,
                                    title: '不通过',
                                },
                            ]"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.title }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="未通过原因"
                    prop="message"
                    v-show="changeStatusDialog.fields.status === 3"
                >
                    <el-input
                        type="textarea"
                        v-model="changeStatusDialog.fields.message"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="changeStatusSubmit">
                        提交
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { Export2Excel } from '@/utils/excel'
export default {
    data() {
        return {
            searchDatePicker: [],
            list: [],
            listTotal: 0,
            listQuery: {
                pageNum: 1,
                pageSize: 10,
                status: 1,
                startTime: '',
                endTime: '',
            },
            statusList: [
                {
                    id: 1,
                    title: '受理中',
                },
                {
                    id: 2,
                    title: '申请通过',
                },
                {
                    id: 3,
                    title: '不通过',
                },
            ],
            changeStatusDialog: {
                title: '申请审核',
                show: false,
                width: '500px',
                labelWidth: '100px',
                fields: {
                    id: null,
                    message: '',
                    status: '',
                },
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        //查询日期
        searchDate() {
            // console.log('searchDatePicker', this.searchDatePicker)
            console.log('startTime', this.searchDatePicker[0])
            this.listQuery.pageNum = 1
            if (this.searchDatePicker != null) {
                this.listQuery.startTime = this.searchDatePicker[0]
                this.listQuery.endTime = this.searchDatePicker[1]
            } else {
                this.listQuery.startTime = ''
                this.listQuery.endTime = ''
            }
            this.getList()
        },
        // 分页处理
        pageNumChange(page) {
            if (page != this.listQuery.pageNum) {
                this.listQuery.pageNum = page
                this.getList()
            }
        },
        // 获取列表
        getList() {
            this.$api.department.apply_page(this.listQuery).then((res) => {
                this.listTotal = res.pageInfo.total
                this.list = res.data
            })
        },
        // 选择状态
        selectStatus() {
            this.listQuery.pageNum = 1
            this.getList()
        },
        getStatusStr(str) {
            let label = ''
            this.statusList.forEach((item) => {
                if (item.id === str) {
                    label = item.title
                }
            })
            return label
        },
        // 审核
        changeStatus(item) {
            this.changeStatusDialog.fields.id = item.id
            this.changeStatusDialog.fields.message = item.message
            this.changeStatusDialog.fields.status = item.status
            this.changeStatusDialog.show = true
        },
        // 审核 - 提交
        changeStatusSubmit() {
            this.$api.department
                .apply_updateStatus(this.changeStatusDialog.fields)
                .then(() => {
                    this.$message({
                        type: 'success',
                        duration: 1000,
                        message: '成功',
                    })
                    this.changeStatusDialog.show = false
                    this.getList()
                })
        },
        //导出excel
        excelOut() {
            const loading = this.$loading({
                lock: true,
                text: '人员导入中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            this.$api.department
                .apply_list(this.listQuery)
                .then((res) => {
                    // console.log(res)
                    if (res.length > 0) {
                        for (let index = 0; index < res.length; index++) {
                            if (res[index].status == 1) {
                                res[index].status = '受理中'
                            } else if (res[index].status == 2) {
                                res[index].status = '申请通过'
                            } else {
                                res[index].status = '不通过'
                            }
                        }
                        Export2Excel(
                            '单位申请列表',
                            [
                                '单位名称',
                                '申请人',
                                '身份证',
                                '联系方式',
                                '申请日期',
                                '申请状态',
                                '备注',
                            ],
                            [
                                'departmentName',
                                'userName',
                                'cardNo',
                                'phone',
                                'createTime',
                                'status',
                                'remark',
                            ],
                            res
                        )
                        loading.close()
                    } else {
                        this.$message({
                            duration: 1000,
                            message: '没有可导出的数据',
                            type: 'error',
                        })
                        loading.close()
                        return false
                    }
                })
                .catch(() => {
                    loading.close()
                })
        },
    },
}
</script>
